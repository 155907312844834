import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { Link, Trans } from '../../../plugins/gatsby-plugin-react-i18next'

const Footer: React.FC = () => {
  return (
    <>
      <div className="max-w-2xl px-6 py-12 mx-auto">
        <div className="w-full space-y-4 text-center">
          <Link to="/">
            <StaticImage
              className="mb-2"
              height={18}
              src="../../../assets/images/playa-pallet-logo-footer.png"
              alt="Playa Pallet"
            />
          </Link>
          <p className="leading-6 text-primary">
            <small>
              © {new Date().getFullYear()}{' '}
              <Trans>
                Playa Pallet is a cooperative project of Ayuntamiento La Oliva, Cabildo de
                Fuerteventura, Biosférate and Clean Ocean Project.
              </Trans>
            </small>
          </p>
          <div>
            <Link
              to="/privacy"
              className="items-center justify-center px-2 py-1 space-x-2 text-teal-700 transition-all duration-300 bg-transparent rounded hover:bg-gray-200 disabled:cursor-not-allowed disabled:opacity-50"
            >
              <small>
                <Trans>Privacy Policy</Trans>
              </small>
            </Link>
          </div>
        </div>
      </div>
      <div className="px-6 py-2 bg-teal-500">
        <div className="flex flex-wrap justify-center max-w-2xl mx-auto">
          <a className="px-6 py-2" href="https://www.laoliva.es/" target="blank">
            <StaticImage
              height={40}
              src="../../../assets/images/logo-ayuntamiento.png"
              alt="La Oliva"
            />
          </a>
          <a className="px-6 py-2" href="http://www.cabildofuer.es/cabildo/" target="blank">
            <StaticImage
              height={40}
              src="../../../assets/images/logo-cabildo.png"
              alt="Cabildo Fuerteventura"
            />
          </a>
          <a className="px-6 py-2" href="http://www.biosferate.es/" target="blank">
            <StaticImage
              height={32}
              src="../../../assets/images/logo-biosferate.png"
              alt="Biosferate"
            />
          </a>
          <a className="px-6 py-2" href="http://cleanoceanproject.org" target="blank">
            <StaticImage
              height={40}
              src="../../../assets/images/logo-cop.png"
              alt="Clean Ocean Project"
            />
          </a>
        </div>
      </div>
    </>
  )
}

export default Footer
