import React from 'react'
import { Helmet, Link } from '../../../plugins/gatsby-plugin-react-i18next'
import Footer from '../../components/footer'
import Header from '../../components/header'
import { dispatch } from '../../state'

const PrivacyPage = () => {
  React.useEffect(() => {
    dispatch({ type: 'setHeaderInverted', value: true })
  }, [])

  return (
    <>
      <Helmet>
        <title>Privacy Policy ES</title>
        <meta name="description" content={`t('hero.intro.1') t('hero.intro.2')`} />
        <meta name="keywords" content="gatsby, react, wapps, i18next" />
      </Helmet>
      <Header />
      <div className="max-w-2xl mx-auto px-6 pt-24">
        <div className="mb-8">
          <Link to="/" className="text-teal-500">
            A la página de inicio
          </Link>
        </div>
        <h1 className="text-center text-3xl uppercase">Política de Privacidad</h1>

        <p>
          Esta Política de Privacidad describe cómo se recopila, utiliza y comparte su información
          personal cuando visites playapallet.org (el "Sitio").
        </p>

        <h3 className="mt-8">INFORMACIÓN PERSONAL QUE RECOGEMOS</h3>

        <p>
          Cuando usted visita el Sitio, utilizamos cierta información sobre su dispositivo,
          incluyendo información sobre su navegador web, y algunas de las cookies que se instalan en
          su para discernir qué idioma mostrarle. También guardamos el idioma seleccionado con tu
          navegador, para que tengas el mismo idioma la próxima vez que lo visites.
        </p>

        <p>
          Recopilamos la información del dispositivo utilizando "Cookies", que son archivos de datos
          que se colocan en su o computadora y a menudo incluyen un identificador único anónimo.
          Para más información sobre las cookies, y cómo deshabilitarlas, visita
          http://www.allaboutcookies.org.
        </p>

        <p>
          Cuando hablamos de "Información Personal" en esta Política de Privacidad, estamos hablando
          de ambos sobre la información del dispositivo y la información mayúsculas.
        </p>

        <h3 className="mt-8">¿CÓMO UTILIZAMOS SU INFORMACIÓN PERSONAL?</h3>

        <p>
          Utilizamos la información que recogemos sólo para proporcionar los servicios del sitio: El
          y el lenguaje del contenido que se ve. Además, nosotros usar esta información para
          comunicarse con usted, si usted reportó un problema con una paleta o se puso en contacto
          con nosotros sobre otros temas.
        </p>

        <h3 className="mt-8"> COMPARTIR SU INFORMACIÓN PERSONAL</h3>

        <p>
          CUtilizamos Google Maps para mostrarle las ubicaciones de los paletas y para determinar su
          geolocalización. Puedes ver la política de privacidad de Google Maps aquí:
          https://policies.google.com/privacy?hl=en-US. También puede decidir en su navegador, si
          ...quieres usar tu geolocalización privada o no.
        </p>

        <p>
          Por último, también podemos compartir su información personal para cumplir con las leyes
          aplicables y para responder a una citación, orden de registro u otra solicitud legítima de
          la información que recibimos, o para proteger de otra manera nuestros derechos.
        </p>

        <p>
          Si es residente europeo, tiene derecho a acceder a la información personal que tenemos
          sobre usted y pedir que su información personal sea corregida, actualizada o eliminada. Si
          Si desea ejercer este derecho, por favor, póngase en contacto con nosotros a través de la
          información de contacto abajo.
        </p>

        <h3 className="mt-8">CAMBIOS</h3>

        <p>
          Podemos actualizar esta política de privacidad de vez en cuando para reflejar, por
          ejemplo, cambios en nuestras prácticas o por otras razones operacionales, legales o
          reglamentarias.
        </p>

        <h3 className="mt-8">CONTÁCTANOS</h3>

        <p>
          Para más información sobre nuestras prácticas de privacidad, si tiene preguntas, o si
          desea Si desea presentar una queja, póngase en contacto con nosotros por correo
          electrónico en info@cleanoceanproject.org o por correo electrónico utilizando los detalles
          que se indican a continuación:
        </p>

        <p>Calle Los Quemados, Lajares, Islas Canarias, 35650, España</p>
      </div>
      <Footer />
    </>
  )
}

export default PrivacyPage
