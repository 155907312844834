import React from 'react'
import { useGlobalState } from '../../state'
import LocaleSwitch from '../localeSwitch'

const Header = () => {
  const [headerInverted] = useGlobalState('headerInverted')

  return (
    <div className="absolute z-50 flex justify-end w-full px-4 pt-4 md:px-8 md:pt-8">
      <div> </div>
      <LocaleSwitch scheme={headerInverted ? 'dark' : 'light'} />
    </div>
  )
}

export default Header
