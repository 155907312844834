import { Popover, Transition } from '@headlessui/react'
import classNames from 'classnames'
import React from 'react'
import BubbleIcon from '../../../assets/svgs/bubble.svg'
import { useI18next } from '../../../plugins/gatsby-plugin-react-i18next'
import { ButtonGhost } from '../buttons'

export interface LanguageSwitchProps {
  scheme: 'light' | 'dark'
}

const LanguageSwitch: React.FC<LanguageSwitchProps> = ({ scheme }) => {
  const { languages, language, changeLanguage } = useI18next()

  return (
    <div className="relative">
      <Popover>
        <Popover.Button className="flex items-center justify-center">
          <BubbleIcon
            width="32"
            height="25"
            className={classNames({
              'stroke-current mt-1': true,
              'text-white': scheme === 'light',
              'text-primary': scheme !== 'light'
            })}
          />
          <p
            className={classNames({
              'absolute text-white text-center text-xs font-bold !ml-0 top-1/2 transform -translate-y-1/2 -mt-px':
                true,
              'text-white': scheme === 'light',
              'text-primary': scheme !== 'light'
            })}
          >
            {language.toUpperCase()}
          </p>
        </Popover.Button>
        <Transition
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <Popover.Panel className="absolute p-4 mt-4 -ml-4 space-y-2 bg-white border border-gray-300 rounded-lg shadow-lg">
            {languages.map((lng) => (
              <ButtonGhost
                key={lng}
                onClick={() => {
                  changeLanguage(lng)
                }}
                disabled={lng === language}
                className={`py-1 px-2 rounded focus:outline-none ${
                  lng === language && 'bg-gray-200'
                }`}
              >
                {lng.toUpperCase()}
              </ButtonGhost>
            ))}
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  )
}

export default LanguageSwitch
